import { Card, Dropdown, MegaMenu } from "flowbite-react"
import { Link, useNavigate } from "react-router-dom"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import GRTA_CAN from "../../assets/images/GRTA_CAN.png"
import AzRTE_CAN from "../../assets/images/AzRTE_CAN.png"

const NavbarCoursesDropdown = () => {
  const navigate = useNavigate()
  return (
    <MegaMenu.Dropdown
      toggle={<>Courses</>}
      theme={{
        base: "w-full xs:!max-w-full xl:w-1/2 !left-1/2 !-translate-x-1/2 !translate-y-44 md:!translate-y-20",
        toggle: {
          arrowIcon: "ml-2 h-4 w-4",
          content: "py-1 focus:outline-none",
          floating: {
            animation: "transition-opacity",
            arrow: {
              base: "absolute z-10 h-2 w-2 rotate-45",
              style: {
                dark: "bg-neutral-900 dark:bg-neutral-700",
                light: "bg-white",
                auto: "bg-white dark:bg-neutral-700",
              },
              placement: "-4px",
            },
            base: "z-10 w-fit rounded divide-y divide-neutral-100 shadow-2xl focus:outline-none",
            content: "py-1 text-sm text-neutral-700 dark:text-neutral-200",
            divider: "my-1 h-px bg-neutral-100 dark:bg-neutral-600",
            header:
              "block py-2 px-4 text-sm text-neutral-700 dark:text-neutral-200",
            hidden: "invisible opacity-0",
            item: {
              container: "",
              base: "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-neutral-700 focus:bg-neutral-100 focus:outline-none dark:text-neutral-200 dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white",
              icon: "mr-2 h-4 w-4",
            },
            style: {
              dark: "bg-neutral-900 text-white dark:bg-neutral-800",
              light: "border border-neutral-200 bg-white text-neutral-900",
              auto: "border border-neutral-200 bg-white text-neutral-900 dark:border-none dark:bg-neutral-800 dark:text-white",
            },
            target: "w-fit",
          },
          inlineWrapper:
            "flex py-2 pr-4 pl-3 md:p-0 border-b border-neutral-100 items-center dark:border-neutral-700  text-neutral-900 dark:text-white hover:text-neutral-400 hover:dark:text-neutral-400 md:border-0 hover:bg-transparent md:hover:text-neutral-400 dark:hover:bg-transparent font-bold uppercase font-hacktricks",
        },
      }}>
      {/* TODO do we want to generate this list from the API? */}
      <ul className="grid lg:grid-cols-2">
        <div className="space-y-4 p-4">
          <Dropdown.Item
            onClick={() => {
              navigate("/courses/arte")
            }}
            theme={{
              base: "flex w-full items-center justify-start text-sm text-neutral-800 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-500 dark:hover:text-white",
            }}>
            <Card
              horizontal={true}
              imgSrc={ARTE_CAN}
              className="w-full dark:bg-neutral-800 items-center rounded-none border-0 shadow-none hover:bg-neutral-200 hover:dark:bg-neutral-700"
              theme={{
                img: {
                  base: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  horizontal: {
                    on: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  },
                },
                root: {
                  base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-700 dark:bg-neutral-800",
                  children: "flex h-full flex-col justify-center gap-4",
                  horizontal: {
                    off: "flex-col",
                    on: "flex-row",
                  },
                  href: "hover:bg-neutral-100 dark:hover:bg-neutral-700",
                },
              }}>
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                AWS Red Team Expert
              </h5>
            </Card>
          </Dropdown.Item>
        </div>
        <div className="space-y-4 p-4">
          <Dropdown.Item
            onClick={() => {
              navigate("/courses/arta")
            }}
            theme={{
              base: "flex w-full items-center justify-start text-sm text-neutral-800 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-500 dark:hover:text-white",
            }}>
            <Card
              horizontal={true}
              imgSrc={ARTA_CAN}
              className="w-full dark:bg-neutral-800 items-center rounded-none border-0 shadow-none  hover:bg-neutral-200 hover:dark:bg-neutral-700"
              theme={{
                img: {
                  base: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  horizontal: {
                    on: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  },
                },
                root: {
                  base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-700 dark:bg-neutral-800",
                  children: "flex h-full flex-col justify-center gap-4",
                  horizontal: {
                    off: "flex-col",
                    on: "flex-row",
                  },
                  href: "hover:bg-neutral-100 dark:hover:bg-neutral-700",
                },
              }}>
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                AWS Red Team Apprentice
              </h5>
            </Card>
          </Dropdown.Item>
        </div>
        <div className="space-y-4 p-4">
          <Dropdown.Item
            onClick={() => {
              navigate("/courses/grte")
            }}
            theme={{
              base: "flex w-full items-center justify-start text-sm text-neutral-800 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-500 dark:hover:text-white",
            }}>
            <Card
              horizontal={true}
              imgSrc={GRTE_CAN}
              className="w-full dark:bg-neutral-800 items-center rounded-none border-0 shadow-none  hover:bg-neutral-200 hover:dark:bg-neutral-700"
              theme={{
                img: {
                  base: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  horizontal: {
                    on: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  },
                },
                root: {
                  base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-700 dark:bg-neutral-800",
                  children: "flex h-full flex-col justify-center gap-4",
                  horizontal: {
                    off: "flex-col",
                    on: "flex-row",
                  },
                  href: "hover:bg-neutral-100 dark:hover:bg-neutral-700",
                },
              }}>
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                GCP Red Team Expert
              </h5>
            </Card>
          </Dropdown.Item>
        </div>
        <div className="space-y-4 p-4">
          <Dropdown.Item
            onClick={() => {
              navigate("/courses/grta")
            }}
            theme={{
              base: "flex w-full items-center justify-start text-sm text-neutral-800 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-500 dark:hover:text-white",
            }}>
            <Card
              horizontal={true}
              imgSrc={GRTA_CAN}
              className="w-full dark:bg-neutral-800 items-center rounded-none border-0 shadow-none  hover:bg-neutral-200 hover:dark:bg-neutral-700"
              theme={{
                img: {
                  base: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  horizontal: {
                    on: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  },
                },
                root: {
                  base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-700 dark:bg-neutral-800",
                  children: "flex h-full flex-col justify-center gap-4",
                  horizontal: {
                    off: "flex-col",
                    on: "flex-row",
                  },
                  href: "hover:bg-neutral-100 dark:hover:bg-neutral-700",
                },
              }}>
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                GCP Red Team Apprentice
              </h5>
            </Card>
          </Dropdown.Item>
        </div>
        <div className="space-y-4 p-4">
          <Dropdown.Item
            onClick={() => {
              navigate("/courses/azrte")
            }}
            theme={{
              base: "flex w-full items-center justify-start text-sm text-neutral-800 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-500 dark:hover:text-white",
            }}>
            <Card
              horizontal={true}
              imgSrc={AzRTE_CAN}
              className="w-full dark:bg-neutral-800 items-center rounded-none border-0 shadow-none hover:bg-neutral-200 hover:dark:bg-neutral-700"
              theme={{
                img: {
                  base: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  horizontal: {
                    on: "h-24 w-24 md:h-32 md:w-32 rounded-t-lg object-cover md:rounded-none md:rounded-l-lg mt-3 md:mt-0",
                  },
                },
                root: {
                  base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-700 dark:bg-neutral-800",
                  children: "flex h-full flex-col justify-center gap-4",
                  horizontal: {
                    off: "flex-col",
                    on: "flex-row",
                  },
                  href: "hover:bg-neutral-100 dark:hover:bg-neutral-700",
                },
              }}>
              <h5 className="text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">
                Azure Red Team Expert
              </h5>
            </Card>
          </Dropdown.Item>
        </div>
      </ul>
      <Link
        to="/courses"
        className="flex mx-5 py-2 text-neutral-800 dark:text-neutral-400 hover:text-red-logo dark:hover:text-red-logo">
        View all courses
      </Link>
    </MegaMenu.Dropdown>
  )
}

export default NavbarCoursesDropdown
