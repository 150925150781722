import { useRef, useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { isAxiosError } from "axios"
import useApi from "../../hooks/useApi"
import { Alert, Button, Label, TextInput } from "flowbite-react"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import useToasts from "../../hooks/useToasts"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"

const TOKEN_REGEX = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,64}$/

const ResetPassword = () => {
  const { toastList, setToastList } = useToasts()
  const { AuthApi } = useApi()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const pwdRef = useRef<HTMLInputElement>(null)
  const errRef = useRef<HTMLInputElement>(null)
  const [token, setToken] = useState("")
  const [validToken, setValidToken] = useState(false)

  const [pwd, setPwd] = useState("")
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState("")
  const [validMatchPwd, setValidMatchPwd] = useState(false)
  const [matchPwdFocus, setMatchPwdFocus] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    pwdRef.current?.focus()
    const t = searchParams.get("token")
    if (t !== null) {
      setToken(t)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [token, pwd, matchPwd])

  useEffect(() => {
    setValidToken(TOKEN_REGEX.test(token))
  }, [token])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd))
    setValidMatchPwd(pwd === matchPwd && pwd !== "")
  }, [pwd, matchPwd])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const v1 = TOKEN_REGEX.test(token)
    const v2 = PWD_REGEX.test(pwd)
    const v3 = pwd === matchPwd
    // if button enabled with JS hack
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await AuthApi.authResetPassword({
        token: token,
        password: pwd,
        match_password: matchPwd,
      })

      if (!response.data.success) {
        setErrMsg("Password reset failed with unknown error")
        return
      }
      //   console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          link: { to: "/signin", text: "Sign in" },
          children: "Password reset successfully!",
        })
      )

      setToken("")
      setPwd("")
      setMatchPwd("")

      navigate("/signin")
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Password reset failed with unknown error")
        }
      } else {
        setErrMsg("Password reset failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Reset your password
            </h2>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}>
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="token" value="Token" />
              </div>
              <TextInput
                id="token"
                type="text"
                placeholder="Token"
                autoComplete="off"
                required={true}
                disabled={true}
                value={token}
                aria-invalid={validToken ? "false" : "true"}
                color={validToken ? "success" : !token ? "" : "failure"}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Password" />
              </div>
              <TextInput
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="password"
                required={true}
                ref={pwdRef}
                onChange={(e) => {
                  setPwd(e.target.value)
                }}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                value={pwd}
                aria-invalid={validPwd ? "false" : "true"}
                color={validPwd ? "success" : !pwd ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={pwdFocus && pwd && !validPwd ? "" : "sr-only"}>
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid password:
                      <br />
                      8 to 64 characters.
                      <br />
                      Must include uppercase and lowercase letters, a number and
                      a special character.
                      <br />
                      Allowed special characters:{" "}
                      <span aria-label="exclamation mark">!</span>{" "}
                      <span aria-label="at symbol">@</span>{" "}
                      <span aria-label="hashtag">#</span>{" "}
                      <span aria-label="dollar sign">$</span>{" "}
                      <span aria-label="percent">%</span>
                    </span>
                  </>
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="confirm_pwd" value="Confirm password" />
              </div>
              <TextInput
                id="confirm_pwd"
                type="password"
                placeholder="Confirm password"
                autoComplete="password"
                required={true}
                onChange={(e) => {
                  setMatchPwd(e.target.value)
                }}
                onFocus={() => setMatchPwdFocus(true)}
                onBlur={() => setMatchPwdFocus(false)}
                value={matchPwd}
                aria-invalid={validMatchPwd ? "false" : "true"}
                color={validMatchPwd ? "success" : !matchPwd ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={
                        matchPwdFocus && matchPwd && !validMatchPwd
                          ? ""
                          : "sr-only"
                      }>
                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid password confirmation:
                      <br />
                      Must match the password input field.
                    </span>
                  </>
                }
              />
            </div>
            <div className="text-sm">
              <p className="font-medium text-gray-600 dark:text-gray-200">
                We will email you instructions to reset your password
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Button
                disabled={
                  !validToken || !validPwd || !validMatchPwd ? true : false
                }
                className="w-full md:w-auto px-20 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ResetPassword
