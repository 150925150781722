import { Alert, Card } from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import Donut from "../../components/graphs/Donut"
import useAuthedApi from "../../hooks/useAuthedApi"
import { isAxiosError } from "axios"
import {
  AWSAccountState,
  AdminDashboardData,
  GCPAccountState,
  AzureAccountState,
} from "../../api"
import Loading from "../../components/Loading"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminDashboard = () => {
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { AdminAuthedApi } = useAuthedApi()
  const [data, setData] = useState<AdminDashboardData>()
  const [loading, setLoading] = useState(true)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getData = async () => {
    try {
      const response = await AdminAuthedApi.adminReadDashboard()
      if (!response.data) {
        setErrMsg("Get dashboard data failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setData(response.data)
      setLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get dashboard data failed with unknown error")
        }
      } else {
        setErrMsg("Get dashboard data failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }>
        <div className="container mx-auto">
          <div className="p-5">
            <section id="data">
              <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                DASHBOARD
              </h1>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}>
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        AWS Accounts
                      </h5>
                      <Donut
                        colors={[
                          "#46e789",
                          "#9a57c7",
                          "#4881f1",
                          "#FDBA8C",
                          "#16b5ca",
                          "#e74646",
                          "#fd8cd7",
                          "#f3eb76",
                        ]}
                        labels={[
                          "Available",
                          "Pending",
                          "Locked",
                          "Provisioning",
                          "Provisioned",
                          "Dirty",
                          "Nuking",
                          "Nuked",
                        ]}
                        series={[
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Available
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Pending
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Locked
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return (
                              account.state === AWSAccountState.Provisioning
                            )
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Provisioned
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Dirty
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Nuking
                          })?.count || 0,
                          data?.aws_accounts.find((account) => {
                            return account.state === AWSAccountState.Nuked
                          })?.count || 0,
                        ]}
                        total_label="AWS Accounts"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        GCP Projects
                      </h5>
                      <Donut
                        colors={[
                          "#46e789",
                          "#9a57c7",
                          "#4881f1",
                          "#FDBA8C",
                          "#16b5ca",
                          "#e74646",
                          "#fd8cd7",
                          "#f3eb76",
                        ]}
                        labels={[
                          "Available",
                          "Pending",
                          "Locked",
                          "Provisioning",
                          "Provisioned",
                          "Dirty",
                          "Nuking",
                          "Nuked",
                        ]}
                        series={[
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Available
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Pending
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Locked
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return (
                              project.state === GCPAccountState.Provisioning
                            )
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Provisioned
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Dirty
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Nuking
                          })?.count || 0,
                          data?.gcp_projects.find((project) => {
                            return project.state === GCPAccountState.Nuked
                          })?.count || 0,
                        ]}
                        total_label="GCP Projects"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Azure Subscriptions
                      </h5>
                      <Donut
                        colors={[
                          "#46e789",
                          "#9a57c7",
                          "#4881f1",
                          "#FDBA8C",
                          "#16b5ca",
                          "#e74646",
                          "#fd8cd7",
                          "#f3eb76",
                        ]}
                        labels={[
                          "Available",
                          "Pending",
                          "Locked",
                          "Provisioning",
                          "Provisioned",
                          "Dirty",
                          "Nuking",
                          "Nuked",
                        ]}
                        series={[
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Available
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Pending
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Locked
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state ===
                              AzureAccountState.Provisioning
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state ===
                              AzureAccountState.Provisioned
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Dirty
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Nuking
                            )
                          })?.count || 0,
                          data?.azure_subscriptions.find((subscription) => {
                            return (
                              subscription.state === AzureAccountState.Nuked
                            )
                          })?.count || 0,
                        ]}
                        total_label="Azure Subscriptions"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Users
                      </h5>
                      <Donut
                        colors={["#46e789", "#e74646"]}
                        labels={["Active", "Inactive"]}
                        series={[
                          data?.users.find((user) => {
                            return user.state === "Active"
                          })?.count || 0,
                          data?.users.find((user) => {
                            return user.state === "Inactive"
                          })?.count || 0,
                        ]}
                        total_label="Users"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        ARTE Vouchers
                      </h5>
                      <Donut
                        colors={["#46e789", "#4881f1"]}
                        labels={["Used", "Unused"]}
                        series={[
                          data?.arte_vouchers.find((arte_vouchers) => {
                            return arte_vouchers.state === "Used"
                          })?.count || 0,
                          data?.arte_vouchers.find((arte_vouchers) => {
                            return arte_vouchers.state === "Unused"
                          })?.count || 0,
                        ]}
                        total_label="ARTE Vouchers"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        ARTA Vouchers
                      </h5>
                      <Donut
                        colors={["#46e789", "#4881f1"]}
                        labels={["Used", "Unused"]}
                        series={[
                          data?.arta_vouchers.find((arta_vouchers) => {
                            return arta_vouchers.state === "Used"
                          })?.count || 0,
                          data?.arta_vouchers.find((arta_vouchers) => {
                            return arta_vouchers.state === "Unused"
                          })?.count || 0,
                        ]}
                        total_label="ARTA Vouchers"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        GRTE Vouchers
                      </h5>
                      <Donut
                        colors={["#46e789", "#4881f1"]}
                        labels={["Used", "Unused"]}
                        series={[
                          data?.grte_vouchers.find((grte_vouchers) => {
                            return grte_vouchers.state === "Used"
                          })?.count || 0,
                          data?.grte_vouchers.find((grte_vouchers) => {
                            return grte_vouchers.state === "Unused"
                          })?.count || 0,
                        ]}
                        total_label="GRTE Vouchers"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        GRTA Vouchers
                      </h5>
                      <Donut
                        colors={["#46e789", "#4881f1"]}
                        labels={["Used", "Unused"]}
                        series={[
                          data?.grta_vouchers.find((grta_vouchers) => {
                            return grta_vouchers.state === "Used"
                          })?.count || 0,
                          data?.grta_vouchers.find((grta_vouchers) => {
                            return grta_vouchers.state === "Unused"
                          })?.count || 0,
                        ]}
                        total_label="GRTA Vouchers"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        AZRTE Vouchers
                      </h5>
                      <Donut
                        colors={["#46e789", "#4881f1"]}
                        labels={["Used", "Unused"]}
                        series={[
                          data?.azrte_vouchers.find((azrte_vouchers) => {
                            return azrte_vouchers.state === "Used"
                          })?.count || 0,
                          data?.azrte_vouchers.find((azrte_vouchers) => {
                            return azrte_vouchers.state === "Unused"
                          })?.count || 0,
                        ]}
                        total_label="AZRTE Vouchers"
                      />
                    </Card>
                    <Card className="[&>div]:mx-auto dark:bg-neutral-900 dark:border-neutral-950">
                      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Certificates
                      </h5>
                      <Donut
                        colors={[
                          "#f59e0b",
                          "#f3c069",
                          "#057a55",
                          "#13b364",
                          "#38bdf8",
                        ]}
                        labels={["ARTE", "ARTA", "GRTE", "GRTA", "AZRTE"]}
                        series={[
                          data?.certificates.find((certificate) => {
                            return certificate.course === "ARTE"
                          })?.count || 0,
                          data?.certificates.find((certificate) => {
                            return certificate.course === "ARTA"
                          })?.count || 0,
                          data?.certificates.find((certificate) => {
                            return certificate.course === "GRTE"
                          })?.count || 0,
                          data?.certificates.find((certificate) => {
                            return certificate.course === "GRTA"
                          })?.count || 0,
                          data?.certificates.find((certificate) => {
                            return certificate.course === "AZRTE"
                          })?.count || 0,
                        ]}
                        total_label="Certificates"
                      />
                    </Card>
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminDashboard
