// import { Link } from "react-router-dom"
import { BannerProps } from "../components/Banner"
import { createContext, PropsWithChildren, useState } from "react"
import AzRTE_CAN from "../assets/images/AzRTE_CAN.png"
import { Button } from "flowbite-react"
import { Link } from "react-router-dom"

export type BannerListData = BannerProps[]

interface BannersContextProps {
  bannerList: BannerListData
  setBannerList: (bannerList: BannerListData) => void
}

const BannersContext = createContext<BannersContextProps>({
  bannerList: [],
  setBannerList: () => {},
})

export const BannersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [bannerList, setBannerList] = useState<BannerListData>([
    {
      id: "azrte_earlybird",
      children: (
        <div className="flex flex-col">
          <img src={AzRTE_CAN} className="px-10 w-full" alt="AzRTE Can" />
          <p className="dark:text-white text-neutral-700">
            AzRTE (Azure Red Team Expert) is now in pre-release with the early
            bird discount applied! Don't lose the opportunity to get it at the
            best price. <br></br>Get ready to improve your Azure & EntraID
            hacking skills!
          </p>
          <Button
            as={Link}
            size="xl"
            className="my-3 mx-10 text-neutral-900 dark:text-white bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-200 hover:dark:bg-neutral-700 !border-sky-400 !ring-sky-400  !ring-2"
            to={`/courses/azrte`}>
            {/* ENROLL NOW */}
            More Info
          </Button>
        </div>
      ),
    },
  ])
  return (
    <BannersContext.Provider value={{ bannerList, setBannerList }}>
      {children}
    </BannersContext.Provider>
  )
}

export default BannersContext
