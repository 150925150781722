import { Sidebar } from "flowbite-react"
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from "react-icons/tb"
import { Link } from "react-router-dom"
import { FC, useEffect, useState } from "react"
import { AiFillDashboard } from "react-icons/ai"
import { FaAws, FaFlask, FaGraduationCap, FaUserAlt } from "react-icons/fa"
import { BsFillTicketPerforatedFill, BsUiChecks } from "react-icons/bs"
import { GrCertificate } from "react-icons/gr"
import { BiLogoGoogleCloud } from "react-icons/bi"
import { VscAzure } from "react-icons/vsc"

export type AdminSiderbarProps = {
  onCollapseChange: (collapsed: boolean) => void
}

const AdminSiderbar: FC<AdminSiderbarProps> = ({ onCollapseChange }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 640)

  const toogleSidebar = () => {
    onCollapseChange(!collapsed)
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width } = window
      if (width < 640) {
        setCollapsed(true)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Sidebar
        className={
          "z-30 mt-20 pb-20 w-64 lg:w-96 fixed inset-y-0 overflow-x-hidden overflow-y-auto block !bg-neutral-900 !dark:bg-neutral-900 transition-all ease-in-out duration-300" +
          (collapsed ? " -translate-x-full" : " translate-x-0")
        }
        theme={{
          root: {
            base: "",
            inner: "",
          },
          item: {
            base: "",
            collapsed: {
              insideCollapse: "",
            },
            content: {
              base: "",
            },
          },
        }}
        aria-label="Sidebar">
        <div className="p-4">
          <Sidebar.Items>
            <Sidebar.ItemGroup className="mt-4 space-y-2 border-t-2 pt-4 first:mt-0 first:border-t-0 first:pt-0 border-neutral-700 dark:border-neutral-700">
              <Sidebar.Item
                as={Link}
                to="/admin"
                icon={AiFillDashboard}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Dashboard</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/users"
                icon={FaUserAlt}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Users</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/courses"
                icon={FaGraduationCap}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Courses</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/labs"
                icon={FaFlask}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Labs</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/exams"
                icon={BsUiChecks}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Exams</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/vouchers"
                icon={BsFillTicketPerforatedFill}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Vouchers</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/certificates"
                icon={GrCertificate}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">Certificates</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/aws_accounts"
                icon={FaAws}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">AWS Accounts</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/gcp_projects"
                icon={BiLogoGoogleCloud}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">GCP Projects</div>
              </Sidebar.Item>
              <Sidebar.Item
                as={Link}
                to="/admin/azure_subscriptions"
                icon={VscAzure}
                className="flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-400 transition duration-75 group-hover:text-white !dark:text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none">
                <div className="whitespace-normal ml-3">
                  Azure Subscriptions
                </div>
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>
      </Sidebar>
      <button
        onClick={() => {
          toogleSidebar()
        }}
        className="p-0 w-10 h-10 bg-red-logo rounded-full hover:bg-red-900 active:shadow-lg mouse shadow transition ease-in duration-50 focus:outline-none z-50 fixed bottom-7 left-7 flex items-center">
        {collapsed ? (
          <TbLayoutSidebarLeftExpand
            size="1.7em"
            color="white"
            className="grow justify-self-center"
          />
        ) : (
          <TbLayoutSidebarLeftCollapse
            size="1.7em"
            color="white"
            className="grow justify-self-center"
          />
        )}
      </button>
    </>
  )
}

export default AdminSiderbar
